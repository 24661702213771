export const getResultSkeletonConfig = () => {
    return {
        "message": null,
        "attributes" : {},
        "endGameType": null,
        "redirectToGame": null,
        "specialEvent": null,
        "previousGame": null,
    };
};

export const getSpecialEventSkeletonConfig = () => {
    return {
        "content": null,
    };
};
