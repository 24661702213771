const CARACTERISTICS_LABELS = [
    'fortuna',
    'imperium',
    'nobilitas',
    'pecunia',
    'virtus'
];

/**
 * Compute the total of caracteristics
 * Input :
 * {
 *      fortuna: 5
        imperium: 4
        nobilitas: 7
        pecunia: 7
        state: 0
        virtus: 7
   }
 * @param caracteristics
 * @returns {number}
 */
export const computeTotalCaracteristics = (caracteristics) => {
    let total = 0;
    Object.keys(caracteristics).forEach((name) => {
        if(CARACTERISTICS_LABELS.includes(name)) {
            total += caracteristics[name];
        }
    });

    return total;
};

/**
 * Returns boolean wether all opponents are deads or not.
 * @param opponents
 * @returns {boolean}
 */
export const areAllOpponentsDead = (opponents) => {
    let allDead = true;
    Object.keys(opponents).forEach((name) => {
        if(opponents[name].isAlive) {
            allDead = false;
        }
    });

    return allDead;
};

/**
 * Return boolean wether all player caracs except imperium are greater than 6 or not.
 * @param player
 * @returns {boolean}
 */
export const areAllCaracsExceptImperiumGreaterThan6 = (player) => {
    let allGreaterThan6 = true;

    Object.keys(player).forEach((name) => {
        if(CARACTERISTICS_LABELS.includes(name) && name !== "imperium" && player[name] < 6) {
            allGreaterThan6 = false;
        }
    });

    return allGreaterThan6;
};

/**
 * Function that kills an opponent
 * @param opponent
 */
export const killOpponent = (opponent) => {
    opponent.isAlive = false;
    opponent.isAllied = false;
    opponent.isAttackable = false;
    opponent.isMurderable = false;
};

export const arrangeCaracsToZero = (player) => {
    Object.keys(player).forEach((carac) => {
        if(CARACTERISTICS_LABELS.includes(carac) && player[carac] < 0 ) {
            player[carac] = 0;
        }
    });
};

export const findAliveOpponent = (opponents) => {
    let survivor = false;
    Object.keys(opponents).forEach((name) => {
        if(opponents[name].isAlive) {
            survivor = opponents[name];
        }
    });

    return survivor;
};
