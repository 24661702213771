/**
 * RoundManagerService
 *
 * handleRoundResult() :
 *  This service contains the rounds logic of our application. Each round we run the handleRoundResult method and
 *  it redirect to the desired page. Most of the time it will redirect to /code/consequences to display the round consequences to the users.
 *
 *  The method also check for the end game status and reset all global parameters for the state related to the currentRound :
 *   - increment currentRound number
 *   - add current code to executed ones
 *   - set current code to null
 *   - set consequences if there are some
 *   - finally navigate to the wanted page
 *
 *  In case we detect an end game case :
 *   - A redirect game is setted :
 *       - we handle dataTransfer between games
 *       - we reset the whole state with the new game initial state
 *       - we redirect users to /code/consequences where it will be explained
 *   - There is no redirect games :
 *       - we set the consequences as their endGame value (with corresponding message etc...)
 *       - we reset the currentCode
 *       - navigate to the redirection page
 *
 */

import {setCurrentCode} from "../../redux/actions/global/setCurrentCode";
import {incrementCurrentRound} from "../../redux/actions/global/incrementCurrentRound";
import {addToExecutedCodes} from "../../redux/actions/global/addToExecutedCodes";
import {navigate} from "gatsby";
import {setConsequences} from "../../redux/actions/global/setConsequences";
import {getState} from "../../redux/stateConstructor";
import {transferDataBetweenGames} from "./DataTransferService";
import {setState} from "../../redux/actions/setState";
import {getResultSkeletonConfig, getSpecialEventSkeletonConfig} from "../../config/ResultSkeletonConfig";
import {getInitialGame} from "../../config/GlobalConfig";
import {
    areAllCaracsExceptImperiumGreaterThan6,
    areAllOpponentsDead,
    computeTotalCaracteristics, findAliveOpponent, killOpponent
} from "./CaracteristicsService";
import {setPlayer} from "../../redux/actions/main/setPlayer";
import {setOpponents} from "../../redux/actions/main/setOpponents";

/**
 *
 * @param currentRound
 * @param gameData
 * @returns {boolean|{attributes, message, endGameType, redirectToGame}}
 */
const checkEndGame = ({currentRound, gameData}) => {
    let consequences = getResultSkeletonConfig();

    // check if we reached the maximum rounds count
    if(currentRound >= gameData.totalRounds) {
        consequences.endGameType = 'totalRoundsReached';
    } else if (gameData.player != null && gameData.player.nobilitas <= 0) {
        consequences.endGameType = 'nobilitasToZero';
    } else if (gameData.player != null && (computeTotalCaracteristics(gameData.player) - gameData.player.imperium) >= 30) {
        consequences.endGameType = 'consensusVictory';
    } else if (gameData.opponents != null && areAllOpponentsDead(gameData.opponents)) {
        consequences.endGameType = 'militaryVictory';
    } else if (gameData.opponents != null && currentRound === 9 && !areAllOpponentsDead(gameData.opponents)) {
        let survivorOpponent = findAliveOpponent(gameData.opponents);

        if(survivorOpponent) {
            if(survivorOpponent.imperium < gameData.player.imperium) {
                consequences.endGameType = "militaryVictory";
            }
        }
    }

    if(consequences.endGameType != null) {
        let messageIndex = consequences.endGameType+"Message";
        if(gameData[messageIndex] != null) {
            consequences.message = gameData[messageIndex];
        }

        if(gameData.redirectToGame != null) {
            consequences.redirectToGame = gameData.redirectToGame;
        }

        return consequences;
    }

    return false;
};

/**
 *
 * @param consequences
 * @param gameData
 * @param currentRound
 * @param dispatch
 * @returns {boolean}
 */
const handleSpecialEvents = ({consequences, gameData, currentRound, dispatch}) => {
    if(gameData.player == null || gameData.opponents == null) {
        return false;
    }

    let savePlayer = false;
    let saveOpponents = false;

    let specialEventContent = [];
    // checks for caracteristics based special events;
    if (gameData.player.nobilitas >=1 && gameData.player.nobilitas <= 2) {
        specialEventContent.push({
            title: "Rumeurs",
            message: "Tes dernières actions n’ont pas été très " +
                     "honorables et ta réputation en a pâti... Si tu " +
                     "ne réagis pas rapidement et ne fais pas " +
                     "quelque chose pour faire remonter ton " +
                     "prestige, certains de tes adversaires " +
                     "pourraient en profiter pour fomenter un " +
                     "complot contre toi, voir tenter de te faire " +
                     "assassiner..."
        });
    }

    if (gameData.player.virtus <= 0 && !gameData.player.isExiled) {
        specialEventContent.push({
            title: "Exil",
            message: "Une cabale contre toi !\n" +
                     "Tes récentes actions ont tellement entaché ta réputation que plusieurs sénateurs se sont offusqués de ton manque de noblesse. Tu n’as plus assez d’influence pour contrer leur manœuvre, et te voici désormais forcer à l’exil de Rome, sans espoir de pouvoir de nouveau t’y rendre…"
        });

        gameData.player.localisation = "province";
        gameData.player.isExiled = true;
        savePlayer = true;
    }

    if (gameData.player.imperium > 5 && !gameData.player.walkOnRomaUnlocked) {
        specialEventContent.push({
            title: "Marche sur Rome",
            message: "La puissance de tes légions est désormais telle que tu " +
                     "peux marcher sur Rome ! Si tu le souhaites, utilise le " +
                     "code « MARCHE » pour tenter de t’imposer dans la " +
                     "capitale impériale par les armes !"
        });

        gameData.player.walkOnRomaUnlocked = true;
        savePlayer = true;
    }

    if (gameData.player.pecunia <= 0) {
        specialEventContent.push({
            title: "Procurateur",
            message: "Tes finances sont épuisées ! Il va falloir les " +
                     "renflouer pour pouvoir rester dans la course. Va voir " +
                     "le Procurateur, il pourra t’aider."
        });
    }

    if (areAllCaracsExceptImperiumGreaterThan6(gameData.player) && !gameData.player.hasSenateDelegation) {
        specialEventContent.push({
            title: "Délégation du Sénat",
            message: "Une délégation de sénateurs se présente à toi." +
                     " Ton aura et ta renommée sont telles qu’ils sont " +
                     "venus pour t’assurer de leur soutien ! Tu es sur " +
                     "la bonne voie, continue !"
        });

        gameData.player.hasSenateDelegation = true;
        savePlayer = true;
    }

    if(specialEventContent.length > 0) {
        consequences.specialEvent = getSpecialEventSkeletonConfig();
        consequences.specialEvent.content = specialEventContent;
    }

    // execute special events specific actions related to currentRound
    if(currentRound === 3) {
        if(!gameData.opponents.niger.isAlive){
            killOpponent(gameData.opponents.iulianus);
            killOpponent(gameData.opponents.albinus);
        } else if (gameData.opponents.albinus.isAllied) {
            killOpponent(gameData.opponents.iulianus);
            killOpponent(gameData.opponents.albinus);
        } else {
            killOpponent(gameData.opponents.iulianus);
            killOpponent(gameData.opponents.niger);
        }

        saveOpponents = true;
    } else if (currentRound === 8) {
        if(gameData.opponents.severe.isAlive && gameData.opponents.albinus.isAlive) {
            killOpponent(gameData.opponents.albinus);
            saveOpponents = true;
        } else if (gameData.opponents.severe.isAlive && gameData.opponents.niger.isAlive) {
            killOpponent(gameData.opponents.niger);
            saveOpponents = true;
        }
    }

    // chek if pecunia raised a bit
    if(gameData.player.pecunia > 0){
        gameData.player.isPoor = false;

        savePlayer = true;
    } else {
        gameData.player.isPoor = true;

        savePlayer = true;
    }

    if(savePlayer) dispatch(setPlayer(gameData.player));
    if(saveOpponents) dispatch(setOpponents(gameData.opponents));
};

/**
 *
 * @param dispatch
 * @param currentCode
 * @param currentRound
 * @param currentGame
 * @param gameData
 * @param consequences
 * @param redirect
 */
const handleRoundResult = ({dispatch, currentCode, currentRound, currentGame, gameData, consequences, redirect = "/code/consequences"}) => {
    // check end game status
    let endGameConsequences = checkEndGame({currentRound, gameData});
    if(endGameConsequences) {
        // set consequences because its used later
        consequences.endGameConsequences = endGameConsequences;
        let newState;
        // if we need to redirect the current gama to another
        if(consequences.endGameConsequences.redirectToGame != null) {
            consequences.previousGame = currentGame;

            let gameType = consequences.endGameConsequences.redirectToGame;
            newState = getState(gameType);

            // managing passing parameters form one game to another
            transferDataBetweenGames({oldState: gameData, newState: newState[gameType]});

        } else {
            newState = getState(getInitialGame());
        }

        // set the new state
        dispatch(setState(newState));

        redirect = "/code/consequences";
    } else {
        // in case we can continue the game
        // incrementer le current round
        dispatch(incrementCurrentRound());
        // TODO: "chercher la nouvelle date" date management

        // ajouter le code aux executés
        dispatch(addToExecutedCodes(currentCode));

        handleSpecialEvents({consequences, gameData, currentRound, dispatch});
    }

    // reset le current code
    dispatch(setCurrentCode(null));

    // set consequences
    dispatch(setConsequences(consequences));

    // navigate to summary page
    navigate(redirect, {replace: true});
};

export {checkEndGame, handleRoundResult}
