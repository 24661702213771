/**
 * DataTransferService
 *
 * The transferDataBetweenGames() method is called from RoundManagerService.handleRoundResult() function.
 * The main goal is to handle data transfer between the redux store of two games.
 * To do that we set up a dataTransfer parameter in the concerned game's parameters (config/games/MainGameConfig.js for example) :
 * dataTransfer:[
     {from: ["playerId"], to: ["player", "playerId"]}
     {from: ["player", "localisation"], to: ["player", "localisation"]} // as sandbox example
   ],
 *
 * This will result as :
 *  - newState.player.playerId = oldState.playerId
 *  - newState.player.localisation = oldState.player.localisation
 *
 * This works with x levels of attributes.
 *
 * Important : dataTransfer is setted in the new game parameters so if you pass from games tuto to main, dataTransfer will be in main and
 * 'from' will reference tuto (oldState) while 'to' will reference main (newState).
 */

/**
 *
 * @param oldState
 * @param newState
 */
export const transferDataBetweenGames = ({oldState, newState}) => {
    if(newState.dataTransfer != null) {
        newState.dataTransfer.forEach((item) => {
            let oldStateKey;
            let oldStateValue = oldState;

            // get the value from the old state that we need to transfer
            for (let i = 0; i < item['from'].length; i++) {
                oldStateKey = item["from"][i];
                oldStateValue = oldStateValue[oldStateKey];
            }

            let newStateKey;
            let newStateValue = newState;

            // browse the new state until we reach the required depth
            // then put the old value previously saved
            for (let i = 0; i < item['to'].length; i++) {
                newStateKey = item["to"][i];

                if (i === (item['to'].length - 1)) {
                    newStateValue[newStateKey] = oldStateValue;
                    break;
                }

                newStateValue = newStateValue[newStateKey];
            }
        });
    }
};
